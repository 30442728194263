<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useI18n, useLocaleRoute } from '#i18n'
import { useSearch } from '~/stores/use-search'

const { locale } = useI18n()
const searchStore = useSearch()
const searchResults = computed(() => {
  return searchStore.searchResults as any[]
})
const searchLoading = computed(() => {
  return searchStore.loading
})
const localeRoute = useLocaleRoute()
const searchInputRef = ref<HTMLInputElement | null>(null)
onMounted(() => {
  searchInputRef?.value?.focus()
})
const searchText = ref('')
const search = (text: string) => {
  if (text.length < 3) return
  searchStore.search(text)
}

const navigate = (searchResult: any) => {
  navigateTo(
    localeRoute(
      `/news/${
        locale.value === 'en'
          ? searchResult.values.pathEN
          : searchResult.values.pathKA
      }`
    )
  )
}

watch(searchText, (value) => {
  if (value.length < 3) {
    searchStore.clearResults()
  }
})
</script>

<template>
  <div
    class="flex border-t-[rgba(255,255,255,0.1)] border-t bg-prussianBlue-950 w-full h-full no-scrollbar overflow-scroll"
  >
    <div class="max-w-[1920px] w-full py-6 px-8 xl:px-32 2xl:mx-auto mx-0">
      <div class="flex">
        <div class="w-full relative">
          <input
            ref="searchInputRef"
            v-model="searchText"
            class="w-full h-[50px] rounded pl-[56px] font-medium font-notoSansGeorgian text-base"
            @keyup.enter="search(searchText)"
          />
          <img
            :class="'brightness-50'"
            :src="'/icons/search.svg'"
            alt="user icon"
            class="inline-block absolute left-2 top-3"
          />
        </div>

        <button
          class="text-xl inline-block font-medium bg-newVisionBlue-900 font-notoSansGeorgian text-white rounded px-11 py-2 ml-4"
          @click="search(searchText)"
        >
          {{ $t('Search') }}
        </button>
      </div>
      <div
        v-if="searchText.length > 0 && !searchLoading"
        class="flex flex-col mt-8"
      >
        <div
          v-for="searchResult in searchResults"
          class="border-t cursor-pointer py-6 border-t-[rgba(255,255,255,0.1)]"
          @click="navigate(searchResult)"
        >
          <span class="text-white font-medium">
            {{
              locale === 'en'
                ? searchResult.values.nodeNameEN
                : searchResult.values.nodeNameKA
            }}
          </span>
          <div class="line-clamp-1">
            <span class="text-white block mt-4 opacity-50 font-normal">
              {{
                locale === 'en'
                  ? searchResult.values.textEN
                  : searchResult.values.textKA
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
